import {FaRunning} from 'react-icons/fa'
import {FaBars} from 'react-icons/fa'
import {FaDiscord} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import PropTypes from 'prop-types'

const Header = ({onToggleMenu}, {title}) => {

  return (
    <nav className='text-xl bg-slate-700 text-lime-500 w-full'>
      <div className='flex flex-row p-3 justify-between md:justify-center items-center gap-6 md:mx-auto'>
            <FaRunning className='text-3xl'/>
            <Link to='/' className='text-3xl md:text-2xl hover:text-lime-300 font-bold hover:scale-105'>DBRC</Link>
            <button onClick={onToggleMenu} className="text-2xl md:hidden hover:text-lime-300 hover:scale-105" > <FaBars className=' text-3xl' /> </button>
            
            <div className='hidden md:flex gap-5'>
              <Link to='/about' className='hover:text-lime-300 hover:scale-105'>ABOUT</Link>
              <Link to='/events' className='hover:text-lime-300 hover:scale-105'>EVENTS</Link>
              <Link to='/partners' className='hover:text-lime-300 hover:scale-105'>PARTNERS</Link>
            </div>
            <div>
              <a href='https://discord.gg/TJpUB9MTCy' className='hidden md:block text-3xl md:text-2xl hover:shadow-md hover:shadow-black rounded-full bg-lime-500 text-slate-700 px-4 hover:bg-lime-300 hover:scale-105'>{FaDiscord} DISCORD</a>
            </div>
      </div>
    </nav>    
  )
};

Header.defaultProps = {
    title: 'DBRC'
}

Header.propTypes = {
    title: PropTypes.string,
}

export default Header
