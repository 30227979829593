import Dublace from '../Media/JD_1080T.png'

function Partners() {
    return (
      <div className='h-screen'>
        <h1 className="text-5xl py-10 text-center">Our Partners</h1>
        <div className="flex px-20 justify-center">
            <div className="">
                <a href="https://www.jimdublace.com">
                <img src={Dublace} alt="JimDublace Studios" width={200}/>
                JimDublace Studios
                </a>
            </div>
        </div>
        <div className='pt-20'>
            <h1 className='text-3xl text-slate-800'>Interested in Becoming a Partner?</h1>
            <p className='text-xl px-20 py-10'>Dad Bod Run Club is dedicated to fostering meaningful connections between local businesses and our community. We carefully select each partner to ensure their values resonate with the core principles of DBRC. If you're interested in partnering with us, please reach out via email at "..."</p>
        </div>
      </div>
    )
  }
  
  export default Partners