import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { useState } from 'react'
import Header from "./Components/Header"
import MobileMenu from './Components/MobileMenu'
import Home from "./Pages/Home"
import Events from "./Pages/Events"
import About from "./Pages/About"
import Partners from './Pages/Partners'
import Footer from './Components/Footer';

const App = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className='h-screen mx-auto'>
        <Header onToggleMenu={toggleMenu} />
        <MobileMenu isOpen={isMenuOpen} onClose={closeMenu} />
        <main className='flex flex-col'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/events' element={<Events />} />
            <Route path='/partners' element={<Partners />} />
            {/* <Route path='/*' element={<NotFound />} /> */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
