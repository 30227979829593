function Footer() {
    const footerYear = new Date().getFullYear()

  return (
    <footer className="footer p-2 bg-slate-700 text-lime-500 text-center ">
      <div>
        <p>Copyright &copy; {footerYear} All rights reserved</p>
      </div>
    </footer>
  )
}

export default Footer
