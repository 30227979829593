
import running from "./../Media/running.gif"
import Community from "./../Media/Community.png"
import Health from "./../Media/Health.png"


function Home() {
  return (
    <div className="">
      {/*First section */}
      <div className="flex flex-col-reverse lg:flex-row bg-slate-600 w-screen"> 
        <div className="flex flex-col lg:flex-row lg:flex-col pt-20 pb-10 pl-10 lg:pl-20">
          <div className="block lg:hidden text-white text-5xl md:text-7xl">DAD BOD RUN CLUB</div>
          <div className="hidden lg:block text-white text-5xl md:text-7xl">DAD<br/> BOD<br/> RUN<br/> CLUB</div>
          <div className="text-slate-100 text-2xl w-2/3 pr-5">Promoting physical and mental health within our ​commu​nity</div>
        </div>
        <div className="flex md:w-auto">
          <img className="rounded-bl-3xl" src={running} alt="running"/>
        </div>
      </div>
      {/*Second section */}
      <div>
        <h1 className="text-center text-5xl md:text-7xl text-lime-600 py-10">ABOUT US</h1>
        <p className="text-2xl px-10 md:px-30">Our mission is to empower local communities by cultivating an environment where positivity and balance thrive. We are dedicated to demonstrating the power of investing in both mental and physical health.</p>
      </div>
      {/*Third section */}
      <div className="flex flex-col md:flex-row mt-5 w-screen">
        <div className="p-5">
          <img src={Community} alt="Community"/>
        </div>
        <div className="flex-2 mx-auto px-5">
          <h1 className="text-5xl text-center md:text-left text-slate-500 py-5">COMMUNITY</h1>
          <p className="text-xl px-5">Dad Bod Run Club is dedicated to fostering a vibrant and inclusive community through the shared passion for running. By organizing regular group runs, training sessions, and social events, we create opportunities for members to connect, support one another, and celebrate achievements together. Our club welcomes runners of all levels, from beginners to seasoned athletes, and provides a supportive environment where friendships are forged and personal goals are achieved. Through our collective effort and camaraderie, we are not just building a running club, but a community that values health, encouragement, and shared experiences.</p>
          <div className="m-10 justify-center">
            <a href="https://discord.gg/TJpUB9MTCy" className="btn">JOIN THE DISCORD</a>
          </div>
        </div>
      </div>
      {/*Fourth section */}
      <div className="flex flex-col md:flex-row bg-slate-600 mt-5 w-screen">
        <div className="p-5 justify-center md:w-full">
          <img src={Health} alt="Health" className="rounded-3xl mx-auto w-2/3 md:w-full"/>
        </div>
        <div className="flex-2 mx-auto text-white pt-10 px-5">
          <h1 className="text-5xl text-center md:text-left">HEALTH</h1>
          <p className="text-xl text-slate-200">Dad Bod Run Club is dedicated to fostering a supportive community where members can enhance both their physical and mental well-being. We facilitate regularly scheduled events to help individuals achieve their health goals and embrace a balanced lifestyle.</p>
          <ul className="text-xl list-disc pl-10">
            <li>Community Events</li>
            <li>Meetups</li>
            <li>Networking Opportunities</li>
          </ul>
          <div className="m-10">
            <a href="/events" className="btn" >EVENT CALENDAR</a>
          </div>
        </div>
        

      </div>
    </div>
    
  )
}

export default Home
