function Events() {
    return (
      <div className="h-screen">
        <div>
          <h1 className="text-5xl py-10">Upcoming Events</h1>
          <p>No events are scheduled at this time. Come back later or join the Discord to stay up to date on upcoming events.</p>
        </div>
        <div>
          <h1 className="text-5xl py-10">Reoccuring Events</h1>
          <ul className="ml-20">
            <li className="list-disc"><span className="font-bold">First Saturday each Month</span> - Amesbury Rail Trail Group Run</li>
          </ul>
        </div>
      </div>
    )
  }
  
  export default Events