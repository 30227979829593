
function About() {
    return (
      <div className="h-screen">
        <div>
          <h1 className="text-5xl py-10">About Dad Bod Run Club</h1>
          <p className="px-10 text-xl">After serving in the Navy, where fitness was a central part of my daily routine, I transitioned into the civilian world and found it challenging to maintain my physical and mental well-being. My first job out of the Navy brought new routines and demands that made it difficult to stay active. This experience ignited a passion in me to connect with others who shared a commitment to community engagement and holistic health. Driven by this desire, I founded Dad Bod Run Club to create a space where like-minded individuals can come together, stay active, and support one another in achieving both physical and mental wellness. My journey from military discipline to building a supportive community underscores my belief in the power of connection and shared goals in promoting a healthier, more engaged life.</p>
        </div>
        <div >
          <h1 className="text-5xl py-10">Core Values</h1>
          <div className="pl-10">
            <h2 className="text-3xl text-lime-500">Community</h2><p>At Dad Bod Run Club, we believe in the power of coming together to build a supportive and inclusive community. We strive to create a welcoming environment where individuals can connect, share experiences, and encourage each other on their fitness journeys.</p>
            <h2 className="text-3xl text-lime-500">Health</h2><p>We are committed to promoting both physical and mental well-being. Our activities and programs are designed to support healthy lifestyles, helping our members achieve their fitness goals while also fostering mental resilience and overall wellness.</p>
            <h2 className="text-3xl text-lime-500">Networking</h2><p>We value the importance of creating meaningful connections. By facilitating opportunities for members to network and engage with one another, as well as with local businesses, we aim to strengthen relationships and build a vibrant, interconnected community.</p>
          </div>
        </div>
      </div>
    )
  }
  
  export default About