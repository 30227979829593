import React from 'react';

const DropdownMenu = ({ isOpen, onClose }) => {
  return (
    <div className={`fixed top-0 right-0 mt-16 w-48 bg-slate-700 text-lime-500 p-4 ${isOpen ? 'block' : 'hidden'}`}>
      <button onClick={onClose} className="text-xl float-right">✖</button>
      <ul>
        <li><a href="/" className="block py-2 hover:text-lime-300 hover:scale-105 px-4">Home</a></li>
        <li><a href="/about" className="block py-2 hover:text-lime-300 hover:scale-105 px-4">About</a></li>
        <li><a href="/events" className="block py-2 hover:text-lime-300 hover:scale-105 px-4">Events</a></li>
        <li><a href="/partners" className="block hover:text-lime-300 hover:scale-105 py-2 px-4">Partners</a></li>
        {/* <li><a href="#contact" className="block hover:text-lime-300 hover:scale-105 py-2 px-4">Contact</a></li> */}
      </ul>
    </div>
  );
};

export default DropdownMenu;